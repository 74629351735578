var projects = [
    {
        name: "Dell IT Platform",
        about: "The IT Admin platform project was a project I led the design of at Dell for IT administrators to manage their fleet of devices.",
        type: ["eng", "pd"],
        hash: "sB9ez9DYApTwVPAwe023",
        password_protected: false,
        visible: true,
        reversed: false,

        hero: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/csg-portal-dashboard%2FIT-Admin-Hero.png?alt=media&token=4cabc608-0862-43c9-b7a6-97212cd8098f",
        heroType: "image",
        heroVisible: true,

        devices: ["computer"],
        phoneContentType: null,
        phoneContent: null,
        computerContentType: "video",
        computerContent: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/csg-portal-dashboard%2FScreen%20Recording%202024-07-06%20at%207.22.46%20PM.mp4?alt=media&token=1d9931ff-ade7-44e7-b76a-38bce3ecb95b",
    },
    {
        name: "Riive",
        about: "Welcome to the world of shareparking. Riive is an app where hosts can lease out driveway space for guests to book parking ahead of time and pay via the app.",
        type: ["eng", "pd"],
        hash: "kkQQCkiCxhH5ZcnzUdHR",
        password_protected: false,
        visible: true,
        reversed: true,

        hero: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/riive%2Fjonas-jaeken-1TmI9BTLmiU-unsplash%20(1).jpg?alt=media&token=9754b49c-f487-43d1-83d3-a715f00fc6fc",
        heroType: "image",
        heroVisible: true,

        devices: ["phone"],
        phoneContentType: "video",
        phoneContent: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/riive%2FRPReplay_Final1677176505.MP4?alt=media&token=db76b952-a93d-447b-b270-ceeaa5fd4b7c",
        computerContentType: null,
        computerContent: null,
    },
    {
        name: "Gloo Design System",
        about: "A design system built to supercharge a startup with over a dozen designers and build trust with thousands of churches and new users.",
        type: ["pd", "eng"],
        hash: "0vjLE9ppyIGxvCIx9QTw",
        password_protected: false,
        visible: true,
        reversed: false,

        hero: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/gloo-design-system%2FGlooHero.png?alt=media&token=1f01497c-3f84-456f-924c-c06f6af08db7",
        heroType: "image",
        heroVisible: true,

        devices: [],
        phoneContentType: null,
        phoneContent: null,
        computerContentType: null,
        computerContent: null,
    },
    {
        name: "U.S. Xpress Carrier Web",
        about: "U.S. Xpress's technology team worked on improving the carrier experience on discovering and booking a load for their drivers.",
        type: ["pd"],
        hash: "tvBqwY7HV54AIcKOFEiK",
        password_protected: false,
        visible: true,
        reversed: true,

        hero: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/us-xpress-carrier-web%2F50738565482_11fbb6eff3_b.jpeg?alt=media&token=416a7247-4905-4b26-90f6-92f05ad9dde5",
        heroType: "image",
        heroVisible: true,

        devices: ["computer"],
        phoneContentType: null,
        phoneContent: null,
        computerContentType: "video",
        computerContent: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/us-xpress-carrier-web%2FScreen%20Recording%202023-03-26%20at%205.52.58%20PM.mp4?alt=media&token=2d0fce3c-e9cc-4cee-bce4-62ac0c810a58",
    },
    {
        name: "MyDell Web",
        about: "MyDell Web was an exploration under the moniker 'Highlander' that helped leadership align on the future direction of peripheral management, computer security and much more from a Dell hosted web app.",
        type: ["pd", "eng"],
        hash: "IVeEyLrKmHKG6PUJD7PF",
        password_protected: false,
        visible: true,
        reversed: false,

        hero: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/highlander-web%2FHeroVideo.mp4?alt=media&token=5d2e073f-bc29-485f-bb43-53ff2d37ff2a",
        heroType: "video",
        heroVisible: true,

        devices: ["computer"],
        phoneContentType: null,
        phoneContent: null,
        computerContentType: "image",
        computerContent: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/highlander-web%2FHighlander-Web-Frame.jpg?alt=media&token=68e10fe6-32d7-4ad0-b22d-316a91cc91bb",
    },
    {
        name: "Project Atlantis",
        about: "This project is under non-disclosure and requires a password to continue.",
        type: ["pd"],
        hash: "bnqKs7YaN4dzegRLXGPr",
        password_protected: true,
        visible: true,
        reversed: false,

        hero: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/dell-atlantis%2Fserverrack_CSiG1SHo%20(2)%20(1).mp4?alt=media&token=d30b7a8d-8bce-46c2-ac6f-b213b89e9193",
        heroType: "video",
        heroVisible: true,

        devices: [],
        phoneContentType: null,
        phoneContent: null,
        computerContentType: null,
        computerContent: null,
    },
    
    {
        name: "UPMC Provider Search",
        about: "Helping users find the care they need by discovering doctors in their plan, and filtering by preference.",
        type: ["eng", "pd"],
        hash: "IamZ2bBn20ml9VmrJw4y",
        password_protected: false,
        visible: true,
        reversed: true,

        hero: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/provider-search%2Fpexels-rfstudio-3825586.jpg?alt=media&token=5cb36258-c986-4129-82d0-3d2a80f55011",
        heroType: "image",
        heroVisible: true,

        devices: ["computer"],
        phoneContentType: null,
        phoneContent: null,
        computerContentType: "video",
        computerContent: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/provider-search%2FScreen%20Recording%202023-03-08%20at%205.37.16%20PM.mp4?alt=media&token=5261208d-9793-4141-a9ea-b25ac1b1fd0f",
    },
    {
        name: "Old Dog, New Tricks",
        about: "Climbing over 14,000' with a senior dog is a challenge worth doing as I create a memory and bond with my childhood dog.",
        type: ["exp"],
        hash: "FFyND0J12fw1hEtQKEhg",
        password_protected: false,
        visible: true,
        reversed: true,

        hero: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/mt-bierstadt-fallon%2Fstart-of-mt-bierstadt-trail.jpg?alt=media&token=b40e8022-d9e5-4881-ac32-2d4e0eecd176",
        heroType: "image",
        heroVisible: true,

        devices: [],
        phoneContentType: null,
        phoneContent: null,
        computerContentType: null,
        computerContent: null,
    },
    {
        name: "Orchid Design System",
        about: "Orchid is UPMC Health Plan's design system that includes everything from color and typography, to pattern designs for components including tables, toggle switches, dropdowns and much more.",
        type: ["pd"],
        hash: "Kj0gXDGP7UsnF8k9Vjhh",
        password_protected: false,
        visible: true,
        reversed: false,

        hero: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/orchid-design-system%2FODS-HERO.png?alt=media&token=68dd2b30-5896-4dd2-83e5-0bf1227e10a9",
        heroType: "image",
        heroVisible: true,

        devices: [],
        phoneContentType: null,
        phoneContent: null,
        computerContentType: null,
        computerContent: null,
    },
    {
        name: "UPMC Chatbot Exploration",
        about: "UPMC Chatbot was an exploration project to discover how we may build trust and lower operational expenses to improve our member experience.",
        type: ["pd"],
        hash: "k3QI626PBI1A0oD4gT99",
        password_protected: false,
        visible: true,
        reversed: false,

        hero: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/chatbot-exploration%2FChatbot-hero%20(1).png?alt=media&token=4d15d670-2be4-4f3f-9a5e-1ce9e3bcc50a",
        heroType: "image",
        heroVisible: false,

        devices: ["phone"],
        phoneContentType: "image",
        phoneContent: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/chatbot-exploration%2FPurple%20Concierge%20Chat.png?alt=media&token=c8b7eff2-f77d-4b1b-80b8-045860399195",
        computerContentType: null,
        computerContent: null,
    },
    {
        name: "Gloo Connect",
        about: "Gloo Connect is an app to help “explorers” using the product and use their community to support them from things like anxiety, loneliness, stress, marriage issues, etc.",
        type: ["pd"],
        hash: "gAv2eCEtF1LHlyOr6HJh",
        password_protected: false,
        visible: true,
        reversed: false,

        hero: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/gloo-connect%2Fpexels-mart-production-7219099.jpg?alt=media&token=01b68d9e-2515-492d-addb-db2b982a808d",
        heroType: "image",
        heroVisible: false,

        devices: ["computer", "phone"],
        phoneContentType: "image",
        phoneContent: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/gloo-connect%2FDirectory%20-%20Auto%20Zipcode%401x.png?alt=media&token=185c570e-961d-4c98-ae70-b27ee513c6d2",
        computerContentType: "image",
        computerContent: "https://firebasestorage.googleapis.com/v0/b/christopherfrydryck-website.appspot.com/o/gloo-connect%2FFrame%2013.png?alt=media&token=60a043f6-ac16-48f5-83aa-7fd726b4d0cb",
    },
]

export default projects