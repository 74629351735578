// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCTsb4yoS5KFrlQlxbeKacBHon_fDI-qwg",
    authDomain: "christopherfrydryck-website.firebaseapp.com",
    projectId: "christopherfrydryck-website",
    storageBucket: "christopherfrydryck-website.appspot.com",
    messagingSenderId: "492626129952",
    appId: "1:492626129952:web:e8a7fa3c0b64b51863b845",
    measurementId: "G-3XB3PCXCXD"
  };

  export default firebaseConfig